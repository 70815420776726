import {TweenMax} from "gsap/TweenMax";

export const Game = {

    step: 1,
    t: .7,
    getCode: '',
    answers: [],
    answer_ids: [],

    init: function () {

        // let nodeStep = document.querySelector('[data-question="'+Game.step+'"]');
        let steps = document.querySelectorAll('.c-step');

        if (steps.length >0){

            window.localStorage.clear();

            Game.chooseOption();
            Game.checkOption();
        }

    },

    nextStep: function() {
        let answers = document.querySelectorAll('.c-answer');
        let previousAnswer = document.querySelector('[data-question="'+Game.step+'"]');
        Game.step++;
        let currentAnswer = document.querySelector('[data-question="'+Game.step+'"]');
        // console.log(previousAnswer,currentAnswer);

        let nextStep = new TimelineMax({
            onComplete:function () {
                answers.forEach(el=>{
                    el.classList.remove('disabled');
                    el.classList.add('start');
                });

            }
        });

        if(Game.step <=5)

        nextStep
            .set(currentAnswer,{visibility:'visible'})
            .to(previousAnswer,.7,{left:'-100vw'},0)
            .to(currentAnswer,.7,{left:0},0)
            .set(previousAnswer,{visibility:'hidden'})
        ;

    },


    checkOption: function () {

        Game.contCheck = 0;
        let checkValues = [];

        const lastBtn = document.querySelector('.c-btn--confirm-check');
        let checks = document.querySelectorAll('.c-answer-check__check');

        /*checks.forEach(check => {
            check.checked = false;
            check.addEventListener('change', (ev) => {
                check.checked ? Game.contCheck++ : Game.contCheck--;
                Game.contCheck > 0 ? lastBtn.disabled = false : lastBtn.disabled = true;
            } ,false);
        });*/


        lastBtn.addEventListener('click', function () {
                checks.forEach(check => {
                    if (check.checked) {
                        checkValues.push(parseInt(check.value));
                        Game.answer_ids.push(check.getAttribute('data-answer-id'));
                    }
                });
            Game.answers.push(checkValues);

            // ultimo bottone di conferma
            // Game.answers
            // console.log(Game.answers);
            if (checkValues.length > 0) Game.getCode += '-' + checkValues.join('');

            //window.localStorage.setItem('question_'+Game.step, JSON.stringify(checkIds));

            window.localStorage.setItem('answer_ids', JSON.stringify(Game.answer_ids));

            window.location.href = this.dataset.link + '?code=' + Game.getCode;


        })

    },

    chooseOption: function () {
        let answers = document.querySelectorAll('.c-answer');
        let LastTimeline = null;
        let LastElem = null;
        Game.hasBeenInterrupped = false;
        Game.contMob = [];
        Game.contDesk = [];
        Game.contOpen = [];
        Game.contClose = [];

        answers.forEach((el,i,all) => {
        // console.log(el,i,all);
            let parent = el.parentElement;
            let title = el.querySelector('.c-answer__title');
            let childNode = parent.querySelectorAll('.c-answer');
            let siblings = Array.from(childNode).filter(c=>c!==el);
            let boxQuestion = parent.querySelector('.c-question');
            let background = el.querySelector('.c-answer__background');
            let icon = el.querySelector('.c-answer__icon');
            let content = el.querySelector('.c-answer__content');
            let box = el.querySelector('.c-answer__box');
            let boxHeight = box.querySelector('div').clientHeight;
            let allTitle = Array.from(childNode).map(el=>el.querySelector('.c-answer__title'));
            let flexGrow = 15;
            let siblingsContent = [];
            siblings.forEach(s=>siblingsContent.push(s.querySelector('.c-answer__content')));
            // console.log(siblingsContent);
            Game.allElements = [answers,title,boxQuestion,background,icon,content,box];

            let confirm = el.querySelector('.c-btn--confirm');
            let change = el.querySelector('.c-btn--change');

            let argsTimeline = {
                paused:true,
                onComplete: () => {
                    confirm.disabled = false;
                    change.disabled = false;
                    siblings.forEach(el=>el.classList.remove('disabled'));
                    setTimeout(()=>siblings.forEach(el=>el.classList.add('collapsed')),200)
                },
                onReverseComplete: () => {
                    answers.forEach(el=>{
                        el.classList.remove('disabled');
                        el.classList.add('start');
                    });
                }
            };


            let tlMob = new TimelineMax(argsTimeline);
            let tlDesk = new TimelineMax(argsTimeline);
            let changeClose = new TimelineMax({
                paused:true
            });
            let changeOpen = new TimelineMax({

                paused:true,
                onComplete: function () {
                    siblings.forEach(el=>{
                        el.classList.remove('disabled');
                        el.classList.add('collapsed');
                    });
                }
            });

            tlMob
                .to(title,.7,{opacity: 0},)

                .to(siblings,.7,{flexGrow: 0},.3)
                .to(boxQuestion,.7,{height: 0},.3)
                .to(background,.7,{opacity: .3,filter:'blur(0)'},.3)
                .to(title,.7,{paddingTop:'25px',paddingBottom:'25px'},.3)

                .set(box,{height:'auto'},.5)
                .from(box,.7,{height:0,immediateRender:false},.5)
                .to(title,.5,{opacity: 1},1.5)
                .to(box,.7,{opacity:1})
            ;

            tlDesk
                .to(allTitle,.2,{opacity: 0},)
                .to(boxQuestion,.3,{opacity:0,y:-50},.2)
                .to(el,.5,{flexGrow: flexGrow},.2)
                .to(siblingsContent,.5,{width: '100%'},.2)
                .to(background,.5,{opacity: .3,filter:'blur(0)'},.2)
                .to(icon,.5,{height:'auto'},.5)
                .to(content,.5,{marginTop:0},.5)
                .to(title,.5,{paddingBottom:'34px'},.5)

                .set(box,{height:'auto'},.5)
                .from(box,.7,{height:0,immediateRender:false},.5)

                .to(title,.5,{'opacity': 1},1.2)
                .to(box,.5,{opacity:1})
            ;

            changeClose
                // .add(()=>console.log('chang Close start'))
                .to(box,.3,{opacity:0})
                .to(title,.3,{opacity: 0})
                .to(background,.5,{opacity: .45,filter:'blur(3px)'},.6)
                .to(title,.5,{paddingBottom:0},.6)
                .to(el,.5,{flexGrow: 1},.6)
                // .to(siblingsContent,.5,{width: '60%'},.6)
                .to(content,.5,{width:'100%',marginTop: '82.5px'},.6)
                .to(icon,.5,{height:'95px'},.6)
                .to(box,.5,{height:0},.6)
            ;
            changeOpen
                // .add(()=>console.log('chang open start'))

                .to(icon,.5,{height:'auto'},.6)
                .to(content,.5,{width:'60%',marginTop: 0},.6)
                .to(el,.5,{flexGrow: flexGrow},.6)
                // .to(siblingsContent,.5,{width: '100%'},.6)

                .to(title,.5,{paddingBottom:'34px'},.6)
                .to(background,.5,{opacity: .3,filter:'blur(0)'},.6)
                .set(box,{height:'auto'},.9)
                .from(box,.5,{height:0,immediateRender:false},.9)
                .to(title,.3,{opacity: 1})
                .to(box,.3,{opacity:1})
            ;

            Game.contMob[i] = tlMob;
            Game.contDesk[i] = tlDesk;
            Game.contOpen[i] = changeOpen;
            Game.contClose[i] = changeClose;

            let timeline = null;

            el.addEventListener('click', clicked, false);
            function clicked() {
                window.innerWidth<1024 ? timeline = tlMob : timeline = tlDesk;

                if(el.classList.contains('collapsed')) {
                    // el.removeEventListener('click', clicked,false);
                    // console.log('is collapsed',el);
                    change.disabled  = false;
                    confirm.disabled = false;
                    // console.log(LastElem);

                    LastElem.querySelector('.c-btn--confirm').disabled  = true;
                    LastElem.querySelector('.c-btn--change').disabled  = true;

                    el.classList.remove('collapsed');
                    answers.forEach(elem=>{
                        elem.classList.remove('collapsed');
                        elem.classList.add('disabled');
                    });
                    // console.log(LastTimeline,changeOpen);

                    LastTimeline.play(0);
                    changeOpen.play(0);

                    LastElem = el;
                    LastTimeline = changeClose;

                    change.addEventListener('click', Close, false);
                    confirm.addEventListener('click', Confirm, false);

                } else if(el.classList.contains('start')) {

                    answers.forEach(el=>el.classList.add('disabled'));
                    answers.forEach(el=>el.classList.remove('start'));

                    timeline.play(0).timeScale(1);

                    change.addEventListener('click', Close, false);
                    confirm.addEventListener('click', Confirm, false);
                    LastTimeline = changeClose;
                    LastElem = el;
                }

                function Close() {
                    // console.log('close');
                    change.disabled  = true;
                    confirm.disabled = true;
                    timeline.seek(timeline._duration/2);
                    timeline.reverse().timeScale(2);
                    answers.forEach(el=>{
                        el.classList.remove('collapsed');
                        el.classList.add('disabled');
                    });
                }

                function Confirm() {

                    change.disabled  = true;
                    confirm.disabled = true;
                    answers.forEach(el=>{
                        el.classList.remove('collapsed');
                        el.classList.add('disabled');
                    });

                    Game.answers.push(parseInt(el.dataset.answer));

                    Game.getCode += el.dataset.answer;

                    Game.answer_ids.push(el.getAttribute('data-answer-id'));

                    //window.localStorage.setItem('question_'+Game.step, JSON.stringify(el.getAttribute('data-answer-id')));

                    // console.log(Game.answers);
                    // console.log('go to step: '+(Game.step +1));
                    Game.nextStep();

                }

            }

        });

        let oldWidth = window.innerWidth;

        window.addEventListener('resize', function () {
            let newWidth = window.innerWidth;
            if(oldWidth-window.innerWidth !== 0) {
                oldWidth = newWidth;

                Game.contMob.forEach(tl=>tl.pause(0));
                Game.contDesk.forEach(tl=>tl.pause(0));
                Game.contOpen.forEach(tl=>tl.pause(0));
                Game.contClose.forEach(tl=>tl.pause(0));
                answers.forEach(elem=>{

                    elem.classList.remove('collapsed','disabled');
                    elem.classList.add('start');

                    let parent = elem.parentElement;
                    let back = elem.querySelector('.c-answer__background');
                    let elements = [
                        elem,
                        parent.querySelector('.c-question'),
                        elem.querySelector('.c-answer__title'),
                        elem.querySelector('.c-answer__icon'),
                        elem.querySelector('.c-answer__content'),
                        elem.querySelector('.c-answer__box'),
                    ];
                    elements.forEach(el=>el.removeAttribute('style'));

                    back.style.filter = '';
                    back.style.opacity = '';
                })

            }

        })

    }
    // ,
    //
    // findAncestor : function (el, cls) {
    //     while ((el = el.parentElement) && !el.classList.contains(cls));
    //     return el;
    // }


};