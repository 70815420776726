export const youtube = {

  init: function () {

    let tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    youtube.video();
  },

  video: function () {

    let $video = document.querySelectorAll('.c-embed');

    let YTString;
    let player;

    window.onYouTubeIframeAPIReady = function() {
      for (let i = 0; i < $video.length; i++) {

      let $this = $video[i];
      YTString = $this.id;

      let autoplay = window.innerWidth <1200 ? 0 : 0;

      let playButton = $this.querySelector('.c-embed__play');
      let cover = $this.querySelector('.c-embed__cover');

        // playButton.style.visibility = 'hidden';
        // cover.style.visibility = 'hidden';

      player = new YT.Player('play-'+YTString, {
        videoId: YTString,
        playerVars: {
          rel: 0,
          controls: 0,
          enablejsapi:1,
          autoplay: autoplay,
          autohide:0,
          // mute: 1,
          showinfo: 0,
          origin : window.location.origin

        },
        events: {
          'onReady': function (e) {
            let video = e.target;

            if(playButton !== null) {
              playButton.addEventListener('click', function (e) {
                e.target.style.visibility = 'hidden';
                cover.style.visibility = 'hidden';
                video.playVideo();
              });
            }

            youtube.checkScroll($this,video);

            window.addEventListener('scroll', function () {
              youtube.checkScroll($this,video);
            })

          },

          'onError': function (event) {
            console.log(event);
          },

          'onStateChange':function (event) {
            if(event.data === YT.PlayerState.ENDED) {
              playButton.style.visibility = 'visible';
              cover.style.visibility = 'visible';
            } else if(event.data === YT.PlayerState.PLAYING) {
              // console.log('started playing');
              playButton.style.visibility = 'hidden';
              cover.style.visibility = 'hidden';
            }

          }
        }
      });

    }
    }
;

  },

  checkScroll: function (elem,video) {

    let target = elem.offsetTop - window.scrollY;
    let height = elem.clientHeight;

    if ((target <=  1) && (target > - height)) {
      // console.log('play');
      video.playVideo();
    } else {
      // console.log('pause');
      video.pauseVideo();
    }
  }

};