export const Backgrounds = {


    init: function () {

        Backgrounds.interchange();
        window.addEventListener('resize',() => Backgrounds.interchange())

    },


    interchange: function () {

        let elements = document.querySelectorAll('[data-background]');


        elements.forEach((el)=>{
            // console.log(el);
            let dataBackSmall = el.dataset.backgroundSmall;
            let dataBackTablet = el.dataset.backgroundTablet;
            // console.log(dataBackSmall,dataBackTablet);

            window.innerWidth<1024 ? el.style.backgroundImage = 'url("'+dataBackSmall+'")' : el.style.backgroundImage = 'url("'+dataBackTablet+'")'

        })

    }

};