export const Profile = {

    init: function () {

        if(document.getElementById('code') !== null) {

            Profile.code();

        }

    },

    code: function () {
        Profile.btn  = document.getElementById('copy');

        Profile.btn.addEventListener('click',  Profile.selection);

    },

    selection: function () {
        Profile.btn.removeEventListener('click',  Profile.selection);
        const input  = document.getElementById('code');
        input.select();
        document.execCommand("copy");

        setTimeout(function () {
            window.getSelection().removeAllRanges();
        },100);

        let message = document.querySelector('.c-copied');

        message.style.visibility = 'visible';
        setTimeout(function () {



            TweenLite.to(message,1,{alpha:0})

        },1000);
        setTimeout(function () {
            message.style.visibility = 'hidden';
            message.style.opacity = '';
            Profile.btn.addEventListener('click',  Profile.selection);
        },2100)

    }

};