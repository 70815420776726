import {TweenMax} from "gsap/TweenMax";
import Cleave     from 'cleave.js';
import axios      from 'axios';





export const Form = {

    validFields: 0,
    inputs:      null,
    privacy:     null,
    newsletter:  null,

    payload: {},


    validateEmail: function(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    init: function() {

        let birthdateField = document.getElementById('birthdate');

        if (birthdateField) {
            new Cleave(birthdateField, {
                date:        true,
                delimiter:   '/',
                datePattern: ['d', 'm', 'Y']
            });
        }

        if (document.getElementById('form') !== null) {
            Form.loader();
        }

    },

    loader: function() {

        const icon = document.querySelector('.c-loader__icon');

        let loader = new TimelineMax();

        loader.to(icon, 1.5, {
            rotation: 360,
            repeat:   -1,
            ease:     Power0.easeNone
        });

        Form.inputs         = document.querySelectorAll('input.c-form__input');
        const registratiBtn = document.getElementById('registrati-btn');
        Form.privacy        = document.getElementById('privacy');
        Form.newsletter     = document.getElementById('newsletter');

        Form.privacy.addEventListener('change', function() {
            Form.validFields = this.checked ? Form.validFields+1 : Form.validFields-1;
        });

        registratiBtn.addEventListener('click', function(e) {

            e.preventDefault();

            Form.validFields = 0;

            Form.inputs.forEach(input => {
                switch (input.type) {
                    case 'email':
                        if (Form.validateEmail(input.value)) Form.validFields += 1;
                        break;
                    default:
                        if (input.value.length > 1) Form.validFields += 1;
                        break;
                }
            });

            Form.validFields += Form.privacy.checked ? 1 : 0;


            if (Form.validFields === 5) {

                registratiBtn.disabled = true;
                Form.submitForm();

            } else {
                document.getElementById('form-error-field').style.display = 'block';
                Form.validFields = 0;
            }

        });

    },


    submitForm: function() {

        Form.inputs.forEach(input => {
            Form.payload[input.getAttribute('name')] = input.value
        });

        Form.payload.getCode                               = document.getElementById('get-code').value;
        Form.payload[Form.privacy.getAttribute('name')]    = Form.privacy.checked;
        Form.payload[Form.newsletter.getAttribute('name')] = Form.newsletter.checked;

        Form.payload['answer_ids'] = window.localStorage.getItem('answer_ids');


        let data = new FormData();
        data.append("json", JSON.stringify(Form.payload));

        fetch("/finalize",
            {
                method: "POST",
                body:   data
            })
        .then(function(res) {
            return res.json();
        })
        .then(function(data) {
            if (data.error !== undefined) {
                let errRep = document.getElementById('error-reporting');
                errRep.innerText = data.message;
                return false;
            } else {

                let form = document.querySelector('.c-form'),
                    loader = document.querySelector('.c-loader');

                form.style.display = "none";
                loader.style.display = "block";

                setTimeout(function() {
                    window.location.href = '/profilo?c='+Form.payload.getCode+'&s=1&n='+data.payload.name;
                }, 3000);

            }
        });


    }
};