import '../scss/app.scss';
// import {loadGameContent} from "./lib/loadGameContent";
import {Game} from "./custom/game";
import {Menu} from "./custom/menu";
import {Form} from "./custom/form";
import {Profile} from "./custom/profile";
import {Accordion} from "./custom/accordion";
import {Video} from "./custom/video";
import {Backgrounds} from "./custom/backgrounds";
import {footerCoupon} from "./custom/footer-coupon";
import {youtube} from "./custom/youtube";

document.addEventListener("DOMContentLoaded", function () {
  // loadGameContent.init();

  youtube.init();
  footerCoupon.init();
  Menu.init();
  Game.init();
  Form.init();
  Profile.init();
  Accordion.init();
  Video.init();
  Backgrounds.init();


});