import {TweenMax} from "gsap/TweenMax";

export const Menu = {
    init:function () {
        Menu.icon = document.querySelector('.c-icon-menu');
        Menu.closeIcon = document.querySelector('.c-icon-close');
        Menu.nav = document.querySelector('.c-nav');

        Menu.check();

        window.addEventListener('resize', Menu.check);




    },

    check: function () {


        if( window.innerWidth <1024 ) {
            Menu.mobile();
        } else {
            Menu.desktop();
        }
    },

    mobile: function () {
        if (Menu.icon !== null) {

            Menu.iconAnimation = new TimelineMax({
                paused:true,
                onComplete:function () {
                    Menu.icon.addEventListener('click', Menu.toggleMenu, false);
                },
                onReverseComplete: function () {
                    Menu.icon.addEventListener('click', Menu.toggleMenu, false);
                }
            });

            Menu.iconHeight = Menu.icon.clientHeight/2-1;

            Menu.iconTop = Menu.icon.querySelector('.c-icon-menu__top');
            Menu.iconBottom = Menu.icon.querySelector('.c-icon-menu__bottom');
            Menu.iconCenter = Menu.icon.querySelector('.c-icon-menu__center');
            Menu.t = .1;
            Menu.iconAnimation
              .to(Menu.iconCenter,Menu.t,{width: 0})
              .to(Menu.iconTop,Menu.t,{y:Menu.iconHeight},Menu.t)
              .to(Menu.iconBottom,Menu.t,{y:-Menu.iconHeight},Menu.t)
              .to(Menu.iconTop,Menu.t,{rotation:33},Menu.t*2)
              .to(Menu.iconBottom,Menu.t,{rotation:-33},Menu.t*2)
            ;


            Menu.icon.addEventListener('click', Menu.toggleMenu, false);

        }
    },

    desktop: function () {

        if (Menu.icon !== null) {
            Menu.icon.addEventListener('click', function () {
                Menu.nav.classList.add('open');
            })
        }
        if (Menu.closeIcon !== null) {
            Menu.closeIcon.addEventListener('click', function () {
                Menu.nav.classList.remove('open');
            })
        }

    },

    toggleMenu: function (ev) {
        Menu.icon.removeEventListener('click', Menu.toggleMenu, false);

        if(Menu.nav.classList.contains('open')) {
            Menu.nav.classList.remove('open');
            Menu.iconAnimation.reverse();
        } else {
            Menu.nav.classList.add('open');
            Menu.iconAnimation.invalidate().play();

        }

    }
};