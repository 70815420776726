export const Accordion = {

    init: function () {

        let accordion = document.querySelectorAll('.c-accordion');

        if(accordion.length>0) {

            accordion.forEach(el=>{

                let cont = el.querySelector('.c-accordion__cont');
                let tab = el.querySelector('.c-accordion__tab');

                tab.addEventListener('click',()=>{

                    if (el.classList.contains('open')) {
                        el.classList.remove('open');
                        TweenLite.to(cont, 0.2, {height:0});
                    }  else {
                        TweenLite.set(cont, {height:"auto"});
                        TweenLite.from(cont, 0.2, {height:0});
                        el.classList.add('open');
                    }

                });
            });

        }


    }

};