import {TweenMax} from "gsap/TweenMax";

export const footerCoupon = {
  init: function () {

    footerCoupon.img = document.querySelector('.c-footerCoupon__coupon');

    if(footerCoupon.img !== null) {

      footerCoupon.animation();

      let winWidth = window.innerWidth;

      window.addEventListener('resize', function () {

        let newWinWidth = window.innerWidth;
        if(newWinWidth!==winWidth) {

          footerCoupon.tl.kill();
          footerCoupon.img.style = '';
          footerCoupon.animation();

          winWidth = newWinWidth;
        }

      })

    }




  },

  animation: function () {
    let rotation = window.innerWidth  < 640 ? 10 : 8;
    let startVar = window.innerWidth  < 640 ? 'translate(-50px,0) rotate(0deg)' : 'translate(-50px,0) rotate(0deg)';
    let endVar = window.innerWidth  < 640 ? 'translate(0,-50%) rotate(-'+rotation+'deg)' : 'translate(50%,-50%) rotate(-'+rotation+'deg)';

    if(footerCoupon.img !== null) {

      TweenLite.fromTo(
        footerCoupon.img,
        1.5,
        {transform:startVar},
        {ease: Power3.easeOut,transform:endVar}
      );

      footerCoupon.tl = new TimelineMax({
        // paused:true,
        repeat:1,
        delay: 3,
        yoyo:true,

        onComplete: function () {
          footerCoupon.tl.pause(0);
          setTimeout(function () {
            footerCoupon.tl.play();
          },5000)
        }
      });

      footerCoupon.tl
        .to(footerCoupon.img,.2,{rotation:-(rotation + 10)})
        .to(footerCoupon.img,.1,{rotation:-(rotation + 6)})
      ;

    }
  }
};