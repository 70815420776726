export const Video = {

    init: function () {
        Video.playStop();
        window.addEventListener('resize',() => Video.playStop())

    },


    playStop: function () {

        let winWidth = window.innerWidth;
        let video = document.querySelector('.c-video');

        if(video !== null) {

            if (winWidth <1024) {
                video.pause();
                video.currentTime = 0;
            } else {
                video.play()
            }

        }


    }

};